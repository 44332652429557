import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";

type CircularProgressLoaderProps = {
  sx?: SxProps;
};

export default function CircularProgressLoader({
  sx,
}: CircularProgressLoaderProps) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
}
