const kilobyte: number = 1024;
const megabyte: number = kilobyte ** 2;
const gigabyte: number = kilobyte ** 3;

export const formatBytes = (byteSize: number, decimalPlaces = 0) => {
  if (byteSize < kilobyte) {
    return `${byteSize}B`;
  }
  if (byteSize < megabyte) {
    return `${(byteSize / kilobyte).toFixed(0)}KB`;
  }
  if (byteSize < gigabyte) {
    return `${(byteSize / megabyte).toFixed(decimalPlaces)}MB`;
  }

  return `${(byteSize / gigabyte).toFixed(decimalPlaces)}GB`;
};

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string,
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export function openFile(fileUrl: string, fileName?: string) {
  const tempLink = document.createElement("a");
  tempLink.href = fileUrl;
  tempLink.setAttribute("target", "_blank");

  if (fileName) {
    tempLink.download = fileName;
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}
