import {
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Patient } from "../../services/types/patient.type";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSessionStorage } from "../../utils/local-storage";
import { useEffect } from "react";
import { PatientPracticeType } from "../../services/types/patient-practice.type";
import SUNO_LOGO from "../../assets/images/logo.svg";
import Stack from "@mui/material/Stack";

interface MainContentHeaderProps {
  patient: Patient;
  practice: PatientPracticeType | undefined;
}

const userChannel = new BroadcastChannel("logout");

export default function MainContentHeader({
  patient,
  practice,
}: MainContentHeaderProps) {
  const theme = useTheme();
  const localAuthSession = useSessionStorage("authToken");
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const logout = () => {
    userChannel.postMessage("SIGN_OUT");
    localAuthSession.clear();
    window.location.reload();
  };

  useEffect(() => {
    userChannel.onmessage = (event) => {
      if (event.data === "SIGN_OUT") {
        localAuthSession.clear();
        window.location.reload();
      }
    };
  }, [localAuthSession]);

  const displayName = patient?.preferred_clinic?.display_name || practice?.name;
  const logoUrl =
    patient?.preferred_clinic?.logo || practice?.logo || SUNO_LOGO;

  return (
    <Box padding={isMobileDevice ? "24px" : "0px"}>
      <Tooltip title="Logout" arrow>
        <Button
          sx={{
            position: "absolute",
            right: "15px",
            top: "15px",
          }}
          onClick={logout}
        >
          <LogoutIcon />
        </Button>
      </Tooltip>
      <Stack
        sx={{
          p: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={logoUrl}
          alt="logo"
          maxHeight={200}
          maxWidth={400}
        />
      </Stack>
      <Typography
        align="center"
        sx={{
          margin: "0 0 40px",
          color: theme.palette.text.secondary,
        }}
        variant="h1"
      >
        Forms Dashboard
      </Typography>
      <Typography variant="body3">
        Welcome <strong>{patient?.fullName ?? ""}</strong>,
      </Typography>
      <Typography variant="body2" sx={{ mt: "10px" }}>
        Please fill out the following forms requested by{" "}
        <strong>{displayName}</strong>
      </Typography>
    </Box>
  );
}
