import React, { useRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

interface ImageCropProps {
  imageSrc: any;
  field?: any;
  setCropImage?: (cropImage: boolean) => void;
}

export default function ImageCrop({
  imageSrc,
  field,
  setCropImage,
}: ImageCropProps) {
  const cropperRef = useRef<ReactCropperElement>(null);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper!.getCroppedCanvas()?.toDataURL()) {
      const base64Data = cropper!.getCroppedCanvas().toDataURL();
      const binaryString = atob(base64Data.split(",")[1]);

      const binaryArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([binaryArray], { type: imageSrc.type });
      const file = [new File([blob], imageSrc.name, { type: imageSrc.type })];
      field.onChange(file);
      if (setCropImage) {
        setCropImage(false);
      }
    }
  };

  return (
    <Stack>
      <Cropper
        src={URL.createObjectURL(imageSrc)}
        style={{ height: 200, width: "100%" }}
        initialAspectRatio={16 / 9}
        guides={false}
        ref={cropperRef}
        background={false}
      />
      <Button
        variant="text"
        size="small"
        onClick={() => {
          onCrop();
        }}
      >
        Crop Image
      </Button>
    </Stack>
  );
}
