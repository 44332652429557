import { type PatientPublicFormInstance } from "services/types/patient-form.type";
import useFetch from "../../../utils/fetch-data";
import { useSessionStorage } from "../../../utils/local-storage";
import { useMemo } from "react";

export const PATIENT_FORMS_PATH_URL = `${process.env.REACT_APP_SUNO_API}/patient-portal/me/forms/`;

const params = {
  ordering: "-created_at",
  form_template__type: 1,
  query: "{*,-document,-data}",
};

interface PatientFormResponse {
  data: PatientPublicFormInstance[];
  isLoading: boolean;
  isError?: boolean;
}

type usePatientFormsProps = {
  type?: number;
  skip?: boolean;
};

export function usePatientForms({
  type = 1,
  skip,
}: usePatientFormsProps): PatientFormResponse {
  const localAuthSession = useSessionStorage("authToken");

  const queryParams = useMemo(
    () => ({ ...params, form_template__type: type }),
    [type],
  );

  const {
    isLoading,
    data = [],
    isError,
  } = useFetch<typeof params, PatientPublicFormInstance[]>({
    method: "GET",
    url: PATIENT_FORMS_PATH_URL,

    token: localAuthSession.getData(null),
    params: queryParams,
    skip,
  });

  return {
    data,
    isLoading,
    isError,
  };
}
