import CheckIcon from "@mui/icons-material/Check";
import InternalErrorPage from "../error";
import { grey } from "@mui/material/colors";
import CircularProgressLoader from "components/loading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MainContentForms from "./main-content-forms.component";
import MainContentHeader from "./main-content-header.component";
import { useIntakeFormSubmissions } from "services/hooks/intake-form/use-intake-form.hook";
import {
  type IntakeFormRequestSubmission,
  IntakeFormSubmissionStatus,
} from "services/types/intake-form-request.type";
import { type Patient } from "services/types/patient.type";
import { type PatientPracticeType } from "services/types/patient-practice.type";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { PURCHASE_AGREEMENT_AUTH_VIA_FEATURE } from "constants/feature-flags";
import { misc, success } from "app/themes/colors";
import { usePatientForms } from "services/hooks/patient-forms/use-patient-forms.hook";
import {
  type OnboardingRequestForm,
  type PatientForm,
  type PurchseAgreementRequestForm,
} from "services/types/patient-form.type";
import {
  ONBOARDING_REQUEST_TYPE,
  PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE,
} from "constants/patient-forms";
import MainContentPurchaseAgreementForms from "./main-content-purchase-agreement-froms.component";

interface LayoutMainContentProps {
  patient: Patient;
  practice: PatientPracticeType | undefined;
  submittedForms: Array<string | number>;
}

export default function LayoutMainContent({
  patient,
  practice,
  submittedForms,
}: LayoutMainContentProps) {
  const flags = useFlags();
  const isPurchaseAgreementEnabled = flags[PURCHASE_AGREEMENT_AUTH_VIA_FEATURE];

  const {
    data: onBoardingRequestsData = [],
    isLoading,
    isError,
  } = useIntakeFormSubmissions();
  const {
    data: publicFormsData = [],
    isLoading: isPublicFormsLoading,
    isError: isPublicFormsError,
  } = usePatientForms({
    skip: !patient?.id || !isPurchaseAgreementEnabled,
  });

  const forms = useMemo(() => {
    if (!Array.isArray(onBoardingRequestsData)) {
      return [];
    }

    const onBoardingRequestForms: OnboardingRequestForm[] = [];
    const patientPublicForms: PurchseAgreementRequestForm[] = [];

    onBoardingRequestsData.forEach((request) => {
      const submissions: IntakeFormRequestSubmission[] =
        request.intake_form_request_submissions?.map((formRequest) => {
          const status = formRequest.status;

          return {
            ...formRequest,
            status:
              status === IntakeFormSubmissionStatus.SUBMITTED ||
              submittedForms.includes(formRequest.intake_form.external_id)
                ? IntakeFormSubmissionStatus.SUBMITTED
                : status,
          };
        }) || [];

      onBoardingRequestForms.push({
        id: request.id,
        created_at: request.created_at,
        type: ONBOARDING_REQUEST_TYPE,
        payload: submissions,
        completed: submissions.filter(
          (form) => form.status === IntakeFormSubmissionStatus.SUBMITTED,
        ).length,
      });
    });

    if (isPurchaseAgreementEnabled && Array.isArray(publicFormsData)) {
      publicFormsData.forEach((publicForm) => {
        patientPublicForms.push({
          id: publicForm.id,
          created_at: publicForm.created_at,
          type: PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE,
          payload: [publicForm],
          saleId: publicForm.sale?.id,
          completed: Boolean(publicForm.signature) ? 1 : 0,
        });
      });
    }

    return [...patientPublicForms, ...onBoardingRequestForms].sort((a, b) => {
      return (
        new Date(b.created_at || "").getTime() -
        new Date(a.created_at || "").getTime()
      );
    });
  }, [
    onBoardingRequestsData,
    submittedForms,
    isPurchaseAgreementEnabled,
    publicFormsData,
  ]);

  const { fullName = "", email = "" } = patient || {};

  if (isLoading || isPublicFormsLoading) {
    return <CircularProgressLoader />;
  }

  if (isError || isPublicFormsError) {
    return <InternalErrorPage />;
  }

  return (
    <Stack
      direction="column"
      className="animate__animated animate__fadeIn"
      width={"100%"}
      sx={{
        p: { md: "0 100px" },
        mb: { xs: "50px" },
      }}
    >
      <MainContentHeader patient={patient} practice={practice} />
      {forms.map((form: PatientForm, index) => {
        const { created_at, completed, payload } = form;
        const isCompleted = Boolean(completed) && completed === payload.length;
        return (
          <Accordion
            key={form.id}
            defaultExpanded={index === 0}
            sx={{
              backgroundColor: isCompleted ? misc.veryLightGreen : grey[50],
              mt: index === 0 ? "20px" : "",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="submission-forms-content"
            >
              <Stack direction="row">
                <Box sx={{ width: "18px", ml: 0, mr: 1 }}>
                  {isCompleted && (
                    <CheckIcon
                      sx={{
                        fontSize: "18px",
                        color: success[700],
                        opacity: 0.7,
                      }}
                    />
                  )}
                </Box>
                <Stack
                  sx={{
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                  }}
                >
                  <Stack direction="column">
                    <Typography variant="body2">
                      {form.type === ONBOARDING_REQUEST_TYPE && (
                        <>Form Submission Request</>
                      )}
                      {form.type === PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE && (
                        <>Purchase Agreement Sign Request</>
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: grey[600],
                        mt: 0.5,
                      }}
                    >
                      Requested on&nbsp;
                      {new Date(created_at || "").toDateString()}
                    </Typography>
                  </Stack>

                  <Typography
                    variant="body1"
                    sx={{
                      ml: {
                        xs: 0,
                        md: 1,
                      },
                      mt: {
                        xs: 0.5,
                        md: 0.15,
                      },
                      color: isCompleted ? grey[600] : grey[900],
                    }}
                  >
                    {`Completed ${completed} of ${payload.length}`}
                  </Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {form.type === ONBOARDING_REQUEST_TYPE && (
                <MainContentForms
                  requestId={form.id}
                  fullName={fullName}
                  email={email}
                  practice={practice}
                  intakeFormRequests={form.payload}
                />
              )}
              {form.type === PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE && (
                <MainContentPurchaseAgreementForms
                  requestId={form.id}
                  fullName={fullName}
                  email={email}
                  practice={practice}
                  requests={form.payload}
                  saleId={form.saleId}
                />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Stack>
  );
}
