import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const PatientFormWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  borderRadius: "5px",
  gap: 16,
  padding: "16px 0",
  flexWrap: "wrap",
  "&:not(:last-of-type)": {
    marginBottom: 8,
  },
}));
export default PatientFormWrapper;
