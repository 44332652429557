import axios from "axios";

export interface BackendRequest<T> {
  url: string;
  method?: "GET" | "POST" | "PATCH";
  body?: T;
  params?: Record<string, string | number>;
  headers?: Record<string, string>;
  token?: string;
}

export interface BackendResponse<T> {
  data: T;
  isError: boolean;
}

export default async function callBackend<T, K>({
  url,
  method = "GET",
  body,
  params,
  headers,
  token,
}: BackendRequest<T>): Promise<BackendResponse<K>> {
  let data: K | undefined | null = null;
  let isError = false;
  try {
    const response = await axios({
      method: method,
      url: url,
      data: body,
      params: params,
      headers: token
        ? {
            Authorization: `Token ${token}`,
            ...headers,
          }
        : headers,
    });
    data = response.data;
  } catch (error: any) {
    data = error?.response?.data;
    isError = true;
  }

  return {
    data: data as K,
    isError,
  };
}
