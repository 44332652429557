import { useCallback, useEffect, useState } from "react";
import callBackend, { BackendRequest } from "../call-backend";

export default function useFetch<T, K>({
  url,
  method,
  body,
  params,
  headers,
  token,
  skip,
}: BackendRequest<T> & { skip?: boolean }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [data, setData] = useState<K>();
  const [timestamp, setTimestamp] = useState<number | undefined>();

  const [requestData, setRequestData] = useState<
    BackendRequest<T> | undefined
  >();

  const runRequest = useCallback(() => {
    if (!requestData) return;
    setIsLoading(true);
    callBackend(requestData).then(({ data, isError }) => {
      setData(data as K);
      setIsError(isError);
      setIsLoading(false);
    });
  }, [requestData]);

  const refetch = useCallback(() => {
    setTimestamp(new Date().getTime());
  }, []);

  useEffect(() => {
    if (skip) {
      setData(undefined);
      setIsError(false);
      setIsLoading(false);
      return;
    }
    setRequestData({ url, method, body, token, params, headers });
  }, [body, method, token, url, skip, params, headers]);

  useEffect(() => {
    runRequest();
  }, [timestamp, requestData, runRequest]);

  return {
    data,
    isLoading,
    isError,
    timestamp,
    refetch,
  };
}
