import useFetch from "../../../utils/fetch-data";
import { PatientInfoResponse, PortalPatient } from "../../types/patient.type";
import { useSessionStorage } from "../../../utils/local-storage";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect, useState } from "react";

const PATIENT_INFO_API_PATH = "/auth/users/me/";

export function usePatientInfo() {
  const localAuthSession = useSessionStorage("authToken");

  const [state, setState] = useState<{
    isLoading: Boolean;
    isError: boolean;
    data: PortalPatient | undefined;
  }>({
    isLoading: false,
    data: undefined,
    isError: false,
  });

  const { isLoading, data, isError }: PatientInfoResponse = useFetch({
    method: "GET",
    url: `${process.env.REACT_APP_SUNO_API}${PATIENT_INFO_API_PATH}`,
    token: localAuthSession.getData(null),
  });

  useEffect(() => {
    setState({
      data: normalizeData(data),
      isLoading,
      isError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return state;
}

function normalizeData(data: PortalPatient | undefined) {
  const portal_patient = data?.portal_patient;
  if (!portal_patient) return data;

  const formatted_portal_patient = {
    ...portal_patient,
    email: portal_patient.email ?? "",
    fullName: [
      portal_patient.first_name,
      portal_patient.middle_name,
      portal_patient.last_name,
    ]
      .filter(Boolean)
      .join(" "),
  };

  if (process.env.REACT_APP_ENV !== "local") {
    datadogRum.setUser({
      id: formatted_portal_patient.id?.toString(),
      email: formatted_portal_patient.email,
      name: formatted_portal_patient.fullName,
      practice: formatted_portal_patient.practice.name,
      practice_id: formatted_portal_patient.practice.id?.toString(),
    });
  }

  // make sure we don't pass null or undefined to downstream components
  return {
    portal_patient: formatted_portal_patient,
  };
}
