import { useState, useCallback, useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import ImageUpload from "components/image-upload";
import SignatureDesk from "components/signature-desk";
import { merge } from "lodash";
import { SignDocumentIcon } from "components/icons";

type SignaturePickerProps = {
  open: boolean;
  isLoading?: boolean;
  hasInitialImage?: boolean;
  title?: string;
  submitButtonName?: string;
  onClose: () => void;
  onSubmit?: (files: File[]) => Promise<void> | void;
};

export type SignaturePickerValues = {
  photos: File[];
};

const DEFAULT_VALUES: SignaturePickerValues = {
  photos: [],
};

export default function SignaturePicker({
  open,
  isLoading,
  title = "Signature",
  submitButtonName = "Save",
  hasInitialImage,
  onClose,
  onSubmit,
}: SignaturePickerProps) {
  const theme = useTheme();
  const [step, setStep] = useState<"main" | "signature-desk">("main");
  const [cropImage, setCropImage] = useState<boolean>(true);

  const defaultValues: SignaturePickerValues = useMemo(() => {
    return merge({}, DEFAULT_VALUES);
  }, []);

  const formMethods = useForm<SignaturePickerValues>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  const handleSubmit: SubmitHandler<{ photos: File[] }> = useCallback(
    async (values) => {
      await onSubmit?.(values.photos);
      formMethods.reset();
      onClose();
    },
    [onSubmit, formMethods, onClose],
  );
  const handleDelete = useCallback(async () => {
    await onSubmit?.([]);
    formMethods.reset();
    onClose();
  }, [onClose, onSubmit, formMethods]);

  const handleClose = useCallback(() => {
    onClose();
    setStep("main");
  }, [onClose]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          width: "400px",
          p: 4,
        }}
      >
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          sx={{ position: "absolute", top: 4, right: 4 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        {step === "main" && (
          <>
            <Typography variant="h5" marginBottom={1} component="div">
              {title}
            </Typography>
            <Typography variant="body1" component="div">
              Draw a signature
            </Typography>
            <IconButton
              onClick={() => setStep("signature-desk")}
              size="large"
              sx={{
                backgroundColor: theme.palette.grey[100],
                mt: 2,
                p: 6,
              }}
            >
              <SignDocumentIcon style={{ height: "24px", width: "24px" }} />
            </IconButton>
            <Divider
              sx={{
                width: 150,
                m: 2,
              }}
            >
              OR
            </Divider>
            <FormProvider {...formMethods}>
              <ImageUpload
                name="photos"
                onRemove={handleDelete}
                hasInitialImage={hasInitialImage}
                cropImage={cropImage}
                setCropImage={setCropImage}
              />
            </FormProvider>
            <LoadingButton
              variant="contained"
              size="large"
              type="submit"
              loading={isLoading}
              sx={{
                marginTop: 2,
              }}
              onClick={formMethods.handleSubmit(handleSubmit)}
            >
              {submitButtonName}
            </LoadingButton>
          </>
        )}
        {step === "signature-desk" && (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              minHeight: "300px",
            }}
          >
            <Box display="flex" alignItems="center" marginBottom={2}>
              <IconButton
                sx={{ marginRight: 1 }}
                onClick={() => setStep("main")}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h5" component="div" fontWeight={700}>
                Signature Desk
              </Typography>
            </Box>
            <SignatureDesk
              width={400}
              height={200}
              onCapture={(file: File) => {
                setStep("main");
                formMethods.setValue("photos", [file]);
              }}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
