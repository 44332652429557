import { useCallback, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { dataUrlToFile } from "utils/common";

const Root = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    borderRadius: "4px",
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

type SignatureDeskProps = {
  width: number;
  height: number;
  onCapture: (file: File) => void;
};

export default function SignatureDesk({
  width,
  height,
  onCapture,
}: SignatureDeskProps) {
  const ref = useRef<SignatureCanvas>(null);
  const handleCapture = useCallback(async () => {
    if (ref.current) {
      const base64 = ref.current.toDataURL();
      const file = await dataUrlToFile(base64, "signature.png");
      onCapture(file);
    }
  }, [onCapture]);

  return (
    <>
      <Root>
        <SignatureCanvas
          ref={ref}
          penColor="black"
          canvasProps={{
            width,
            height,
          }}
        />
      </Root>
      <Button
        variant="contained"
        sx={{
          mt: 2,
        }}
        onClick={handleCapture}
      >
        Confirm Signature
      </Button>
    </>
  );
}
