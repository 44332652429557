import React, { useCallback, useRef } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { styled, useTheme } from "@mui/material/styles";
import { formatBytes } from "utils/common";

import ImageCrop from "components/image-crop";

const CustomBox = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    backgroundColor: theme.palette.common.white,
    borderRadius: "12px",
    boxShadow: `${theme.palette.common.black}19 0px 8px 24px`,
    padding: "8px",
  },
  "&.MuiBox-root:hover, &.MuiBox-root.dragover": {
    opacity: 0.6,
  },
}));

interface ImageUploadProps {
  name: string;
  onRemove?: () => void;
  hasInitialImage?: boolean;
  cropImage?: boolean;
  setCropImage?: (cropImage: boolean) => void;
}

export default function ImageUpload({
  name,
  onRemove,
  hasInitialImage,
  cropImage,
  setCropImage,
}: ImageUploadProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();
  const { field } = useController({ name, control });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const onDragEnter = () => wrapperRef.current?.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current?.classList.remove("dragover");
  const onFileDrop = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement;
      if (!target.files) return;

      const newFile = Object.values(target.files).map((file: File) => file);
      field.onChange(newFile);

      !cropImage && setCropImage && setCropImage(true);
    },

    [field, cropImage, setCropImage],
  );
  const removeFile = useCallback(() => {
    field.onChange([]);
    if (onRemove) {
      onRemove();
    }
  }, [field, onRemove]);

  return (
    <>
      <CustomBox>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "relative",
            minWidth: "300px",
            width: "100%",
            height: "13rem",
            border: `1px dashed ${theme.palette.secondary.main}`,
            borderRadius: "12px",
          }}
          ref={wrapperRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDragLeave}
        >
          <Stack justifyContent="center" sx={{ p: 1, textAlign: "center" }}>
            <div>
              <FileDownloadIcon />
            </div>
            <Typography variant="body1" component="span">
              Upload a Photo
            </Typography>
          </Stack>
          <Controller
            name={name}
            defaultValue=""
            control={control}
            render={({ field: { name, onBlur, ref } }) => (
              <input
                type="file"
                name={name}
                onBlur={onBlur}
                ref={ref}
                onChange={onFileDrop}
                accept="image/jpg, image/png, image/jpeg"
                style={{
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
              />
            )}
          />
        </Box>
      </CustomBox>
      <FormHelperText sx={{ textAlign: "center", my: 1 }}>
        <>{errors && errors[name] ? errors[name]?.message : ""}</>
      </FormHelperText>

      <Stack spacing={2} sx={{ my: 2, minWidth: "300px" }}>
        {field?.value?.map((item: any, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                position: "relative",
                backgroundColor: `${theme.palette.info.light}10`,
                borderRadius: 1.5,
                px: 0.5,
                py: 1,
              }}
            >
              {cropImage ? (
                <ImageCrop
                  setCropImage={setCropImage}
                  imageSrc={item}
                  field={field}
                />
              ) : (
                <>
                  <Box display="flex">
                    <Box display="flex" sx={{ alignItems: "center" }}>
                      <img
                        style={{ height: "24px", width: "24px" }}
                        src={URL.createObjectURL(item)}
                        alt="Preview"
                      />
                    </Box>

                    <Box sx={{ ml: 1, maxWidth: "calc(100% - 50px)" }}>
                      <Typography sx={{ mb: 1 }}>{item.name}</Typography>
                      <Typography variant="body2">
                        {formatBytes(item.size)}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={removeFile}
                    sx={{
                      color: theme.palette.error.main,
                      position: "absolute",
                      right: "0.25rem",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </Box>
          );
        })}

        {hasInitialImage && !Boolean(field?.value?.length) && (
          <Box
            sx={{
              position: "relative",
              backgroundColor: `${theme.palette.info.light}10`,
              borderRadius: 1.5,
              px: 0.5,
              py: 1,
            }}
          >
            <Box>
              <Box sx={{ ml: 1, maxWidth: "calc(100% - 50px)" }}>
                <Typography>Current Image</Typography>
              </Box>
            </Box>
            <IconButton
              onClick={removeFile}
              sx={{
                color: theme.palette.error.main,
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
    </>
  );
}
