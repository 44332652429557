import { datadogRum } from "@datadog/browser-rum";
import {
  withLDProvider,
  LDEvaluationDetail,
} from "launchdarkly-react-client-sdk";
import React from "react";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import setupDatadog from "./setup/datadog";

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID!,
  context: {
    kind: "user",
    key: "anonymous",
    anonymous: true,
  },
  options: {
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
})(App);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <LDProvider />
  </React.StrictMode>,
);

setupDatadog();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
