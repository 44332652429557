import Box from "@mui/material/Box";
import CircularProgressLoader from "components/loading";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMount } from "react-use";
import { PATIENT_FORMS_PATH_URL } from "services/hooks/patient-forms/use-patient-forms.hook";

type PreviewFormDocumentProps = {
  formId: string | number;
  afterPdfDownload: (fileUrl) => void;
};

export default function PreviewFormDocument({
  formId,
  afterPdfDownload,
}: PreviewFormDocumentProps) {
  const containerRef = useRef<HTMLDivElement>();

  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    const fetchPdf = () => {
      const url = `${PATIENT_FORMS_PATH_URL}${formId}/?format=pdf`;
      const token = (sessionStorage.getItem("authToken") || "").replaceAll(
        '"',
        "",
      );

      fetch(url, {
        headers: {
          Accept: "application/pdf",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          afterPdfDownload(url);
          setFileUrl(url);
        });
    };

    if (formId) {
      fetchPdf();
    }
  }, [formId, afterPdfDownload]);

  const adjustHeight = useCallback(() => {
    if (containerRef.current) {
      const height = `${window.innerHeight - (containerRef.current?.getBoundingClientRect().top || 0) - 50}px`;
      containerRef.current.style.height = height;
    }
  }, []);

  useMount(() => {
    adjustHeight();
  });

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        mt: 12,
        ml: 11,
        width: "calc(100% - 190px)",
      }}
    >
      {fileUrl ? (
        <object data={fileUrl} type="application/pdf" style={{ flexGrow: 1 }}>
          <p>Document</p>
        </object>
      ) : (
        <CircularProgressLoader />
      )}
    </Box>
  );
}
